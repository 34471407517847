import React from 'react'
import { Grid, Card, CardContent, Button, Typography } from '@mui/material'
import { createResubmitUrl } from '../../../utils/urlUtils'

export const RetriggerVerificationFooter = ({ completed, taskDetails }) => {
  if (!completed) {
    return null
  }
  const resubmitUrl = createResubmitUrl({ orderId: taskDetails?.orderId, itemIndex: taskDetails?.itemIndex })

  return (
    <Card sx={{ p: 1, m: 1 }}>
      <CardContent>
        <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
          <Grid item xs={4}>
            <Typography>
              Completed tasks can not be completed again. Please trigger
              reverification instead of completing the task again.
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button variant='contained' fullWidth href={resubmitUrl}>
              Retrigger Verification
            </Button>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </CardContent>
    </Card>
  )
}
