import { visit } from 'unist-util-visit'
import { parse } from 'rehype-parse'
import { unified } from 'unified'

// Custom rehype plugin to handle anchor tags in markdown
export const rehypeCustomAnchor = () => {
  return (tree) => {
    visit(tree, 'element', (node, index, parent) => {
      if (node.tagName === 'p') {
        const child = node.children[0]

        // Check if the child is a text node that contains a stringified <a> tag
        if (child && child.type === 'text' && /"<a[^>]*>.*<\/a>"/.test(child.value)) {
          // Parse the stringified <a> tag back into an element
          const parsed = unified()
            .use(parse)
            .parse(child.value.replace(/"/g, ''))

          // Replace the <p> node with the parsed <a> node
          parent.children.splice(index, 1, ...parsed.children)
        }
      }
    })
  }
}

// Custom rehype plugin to handle Mermaid diagrams
export const rehypeCustomMermaid = () => {
  return (tree) => {
    visit(tree, 'element', (node) => {
      if (
        node.tagName === 'pre' &&
        node.children &&
        node.children.length > 0 &&
        node.children[0].tagName === 'code'
      ) {
        const codeNode = node.children[0]
        const className = codeNode.properties.className || []
        const language = Array.isArray(className) ? className : [className]

        if (language.includes('language-mermaid')) {
          node.tagName = 'div'
          node.properties = { className: ['mermaid'] }
          node.children = [
            { type: 'text', value: codeNode.children[0].value },
          ]
        }
      }
    })
  }
}
