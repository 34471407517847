import React, { useContext, useState, useEffect, useMemo } from 'react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { Drawer } from '@mui/material'

import { ErrorDialog } from '../TaskHandler/Shared/ErrorDialog'
import TicketList from './TicketList'
import ApiContext from '../../Services/apiService'
import { waitForStatus } from '../../utils/tasks'

const REFRESH_INTERVAL = 30000

const TicketContainer = () => {
  const api = useContext(ApiContext)
  const location = useLocation()
  const { taskId } = useParams()

  const [liveRefresh, setLiveRefresh] = useState(false)

  const [status, setStatus] = useState('PENDING')
  const [selectedTicket, setSelectedTicket] = useState(null)
  const [reverseOrder, setReverseOrder] = useState(true)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [pageFetching, setPageFetching] = useState(false)

  const [tickets, setTickets] = useState([])
  const [pages, setPages] = useState([undefined])
  const [lastKey, setLastKey] = useState()
  const [errorDialog, setErrorDialog] = useState({ show: false, errorMessage: '' })

  const refreshTickets = useMemo(() => async () => {
    if (selectedTicket) {
      return
    }
    console.log('Refreshing ticket from container', status, reverseOrder)
    setIsRefreshing(true)
    try {
      const { tickets, lastKey } = await api.getTicketSummaries({ status, reverseOrder })

      setTickets(tickets)
      setLastKey(lastKey)
      setPages([undefined])
      console.log('Tickets set', tickets, status, lastKey)
    } catch (err) {
      const errMessage = err.errors ? err.errors[0].message : err.message
      console.log('got error', errMessage)
      setErrorDialog({
        show: true,
        errorMessage: errMessage,
      })
    }
    setIsRefreshing(false)
  }, [selectedTicket, status, reverseOrder])

  const refreshAllPages = useMemo(() => async () => {
    console.log('Refreshing ticket from container', status, reverseOrder)
    setIsRefreshing(true)
    try {
      if (pages.length === 0) {
        const { tickets: newTickets, lastKey: newLastKey } = await
        api.getTicketSummaries({ status, reverseOrder })
        setTickets(newTickets)
        setLastKey(newLastKey)
        setPages([{ tickets: newTickets, lastKey: newLastKey }])
      } else {
        const allPages = await Promise.all(pages.map((pageKey) =>
          api.getTicketSummaries({ status, reverseOrder, lastKey: pageKey })))
        const newTickets = allPages.reduce((acc, page) => [...acc, ...page.tickets], [])
        setTickets(newTickets)
        const lastPage = allPages[allPages.length - 1]
        console.log('last page', lastPage)
        setLastKey(lastPage?.lastKey)
        console.log('Tickets set', newTickets, status, lastPage?.lastKey)
      }
    } catch (err) {
      const errMessage = err.errors ? err.errors[0].message : err.message
      console.log('got error', errMessage)
      setErrorDialog({
        show: true,
        errorMessage: errMessage,
      })
    }
    setIsRefreshing(false)
  }, [pages, status, reverseOrder])

  const handleCloseError = useMemo(() => () => {
    setErrorDialog({
      show: false,
      errorMessage: '',
    })
  }, [setErrorDialog])

  useEffect(() => {
    refreshTickets()
  }, [api, status, reverseOrder])

  useEffect(() => {
    if (taskId !== selectedTicket) {
      if (!taskId) {
        refreshAllPages()
      }
      setSelectedTicket(taskId)
    }
  }, [location, selectedTicket, taskId])

  useEffect(() => {
    let timeout
    if (liveRefresh && !isRefreshing) {
      console.log('setting timer')
      timeout = setInterval(() => {
        refreshTickets()
      }, REFRESH_INTERVAL)
    }
    return () => {
      if (timeout) {
        console.log('clearing timer')
        clearInterval(timeout)
      }
    }
  }, [liveRefresh, isRefreshing])

  const scrollList = useMemo(() => () => {
    if (lastKey) {
      setPageFetching(true)
      api.getTicketSummaries({ status, reverseOrder, lastKey }).then(({ tickets: newTickets, lastKey: newLastKey }) => {
        setTickets([...tickets, ...newTickets])
        setLastKey(newLastKey)
        setPageFetching(false)
        setPages([...pages, lastKey])
      })
    } else {
      console.log('no more results')
    }
  }, [lastKey, status, reverseOrder, tickets, pages])

  const assignTicket = useMemo(() => async ({ taskId }) => {
    const response = await api.assignTask({ type: 'assign', taskId })
    console.log('ticket assigned', response)
    try {
      await waitForStatus(api, taskId, 'ASSIGNED')
    } catch (error) {
      console.error(error.message)
    }
  }, [api])

  const setFilter = useMemo(() => ({ status, reverseOrder, liveRefresh }) => {
    console.log('setting filter', status, reverseOrder, liveRefresh)
    setStatus(status)
    setReverseOrder(reverseOrder)
    setLiveRefresh(liveRefresh)
  }, [setStatus, setReverseOrder, setLiveRefresh])
  const filter = useMemo(() => ({
    status,
    reverseOrder,
    liveRefresh,
  }), [status, reverseOrder, liveRefresh])

  return (
    <>
      <ErrorDialog
        errorMessage={errorDialog.errorMessage}
        open={errorDialog.show}
        onClose={handleCloseError}
      />
      <TicketList
        tickets={tickets}
        refreshTickets={refreshTickets}
        isRefreshing={isRefreshing}
        filter={filter}
        setFilter={setFilter}
        scrollList={scrollList}
        lastKey={lastKey}
        pageFetching={pageFetching}
        assignTicket={assignTicket}
        selectTicket={setSelectedTicket}
        />
      <Drawer open={selectedTicket} anchor='right' onClose={() => setSelectedTicket(null)} PaperProps={{ sx: { width: '80%' } }}>
        <Outlet />
      </Drawer>
    </>

  )
}

export default TicketContainer
