import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'

const Loader = (): React.JSX.Element => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 999999999999999 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}

export default Loader
