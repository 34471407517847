import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import Drawer from '../../Components/Drawer/Drawer'

const Layout = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh', overflow: 'hidden', width: '100vw' }}>
        <Box sx={{ height: '100%' }}>
          <Drawer />
        </Box>
        <Box sx={{ height: '100vh', width: '100vw', overflowY: 'auto', padding: 1, backgroundColor: '#f8f8f8' }}>
          <Outlet />
        </Box>
      </Box>
  )
}

export default Layout
