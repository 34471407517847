import React from 'react'
import TicketContainer from './TicketContainer'

const TaskQueue = () => {
  return (
    <div className="taskQueue">
      <TicketContainer />
    </div>
  )
}

export default TaskQueue
