import React, { useState } from 'react'
import { Box, Button, Card, CardContent, CardActionArea, Grid, Typography, Chip, CircularProgress } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

import OverflowText from '../OverflowText'
import { formatDate } from '../../utils/formatDate'

import { TASK_TYPE_MAP } from '../../Pages/TaskHandler/TaskTypeWrapper'

const TicketType = ({ taskType }) => {
  const { title = taskType, color = 'red' } = TASK_TYPE_MAP[taskType] || {}
  return (
    <Grid item xs={1} sx={{ backgroundColor: color, color: 'white', margin: -2, paddingTop: 2 }}>
      <Typography align="center">{title}</Typography>
    </Grid>
  )
}

const TicketSlaTime = ({ taskSlaTime }) => {
  const iconColor = taskSlaTime < Date.now() ? 'error' : 'info'
  return (
    <Grid item xs={3}>
      <AccessTimeIcon color={iconColor} /> {formatDate({ date: taskSlaTime })}
    </Grid>
  )
}

const TicketCard = ({ onPressed, ticket, onStart }) => {
  const {
    taskId,
    taskType,
    taskSlaTime,
    status,
    taskTitle,
    taskTags,
  } = ticket
  return (
    <Grid container columnSpacing={1} alignItems="stretch">
      <Grid item xs={11}>
        <Card sx={{ marginBottom: 2 }}>
          <CardActionArea onClick={onPressed}>
            <CardContent>
              <Grid container columnSpacing={1}>
                <TicketType taskType={taskType} />
                <Grid item xs={11}>
                  <Grid container>
                  <Grid item xs={2} sx={{ marginLeft: 2 }}>
                      {status}
                    </Grid>
                    <Grid item xs={6} zeroMinWidth>
                      <Typography noWrap component="div">
                        <OverflowText>
                          {taskTitle}
                        </OverflowText>
                      </Typography>
                    </Grid>
                    <TicketSlaTime taskSlaTime={taskSlaTime} />
                    <Grid item xs={12} sx={{ marginLeft: 2 }}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                      }}>
                          {taskTags && taskTags.map(({ label, value }) => (
                            <Chip key={`task-${taskId}-${label}`} sx={{ margin: '0.25rem' }} label={`${label}: ${value}`} />
                          ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item xs={1} sx={{ marginBottom: '1rem' }}>
        {status === 'PENDING' &&
          <AsyncButton variant="contained" sx={{ height: '100%' }} onClick={onStart}>Start Task</AsyncButton>}
      </Grid>
    </Grid>
  )
}

const AsyncButton = ({ onClick, sx, variant, children, progressColor }) => {
  const [isPressed, setIsPressed] = useState(false)
  const handler = async () => {
    setIsPressed(true)
    await onClick()
    setIsPressed(false)
  }
  if (isPressed) {
    return (
      <Button variant={variant} sx={sx} onClick={handler}>
        <CircularProgress color={progressColor} />
      </Button>
    )
  }
  return (
    <Button variant={variant} sx={sx} onClick={handler}>{children}</Button>
  )
}

export default TicketCard
