import React, { useMemo, useState } from 'react'
import {
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  Typography,
  Chip,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Dialog, TextField,
} from '@mui/material'

import { formatDate } from '../../../utils/formatDate'

const TaskAssignment = ({ manualTask, assignTask }) => {
  const disabled = useMemo(() => {
    return ['COMPLETED', 'CANCELLED', 'ON_HOLD'].includes(manualTask.status)
  }, [manualTask.status])
  if (manualTask.assignedUser) {
    return (
      <Box>
        <Button variant="outlined" disabled={disabled} onClick={assignTask}>Unassign Task</Button>
      </Box>
    )
  }
  return (
    <Box>
      <Button variant="outlined" disabled={disabled} onClick={assignTask}>Assign Task to me</Button>
    </Box>
  )
}

const TaskOnHold = ({ manualTask, onHoldTask, resumeTask }) => {
  const disabled = useMemo(() => {
    return ['PENDING', 'COMPLETED', 'CANCELLED'].includes(manualTask.status)
  }, [manualTask.status])
  if (manualTask.status === 'ON_HOLD') {
    return (
     <Box>
       <Button variant="outlined" disabled={disabled} color="success" onClick={resumeTask}>Resume</Button>
     </Box>
    )
  }
  return (
   <Box>
     <Button variant="outlined" disabled={disabled} color="warning" onClick={onHoldTask}>Put On Hold</Button>
   </Box>
  )
}

const OnHoldTaskDialog = ({ open, handleClose, handleConfirm }) => {
  const [reason, setReason] = useState('')

  const handleReasonChange = (event) => {
    setReason(event.target.value)
  }

  const handleConfirmClick = () => {
    handleConfirm(reason)
  }

  return (
   <Dialog open={open} onClose={handleClose}>
     <DialogTitle>Put Task On Hold</DialogTitle>
     <DialogContent>
       <DialogContent>
         <DialogContentText>Are you sure you want to put this task on hold?</DialogContentText>
         <TextField
          autoFocus
          margin="dense"
          id="reason"
          label="Reason for putting task on hold"
          type="text"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={reason}
          onChange={handleReasonChange}
         />
       </DialogContent>
     </DialogContent>
     <DialogActions>
       <Button onClick={handleClose} color="primary">
         Cancel
       </Button>
       <Button onClick={handleConfirmClick} color="warning" variant="contained">
         Put on Hold
       </Button>
     </DialogActions>
   </Dialog>
  )
}

const TaskStatus = ({ status }) => {
  const color = status === 'COMPLETED' ? 'success.main' : 'text.primary'
  return (
    <Typography align="left">
      Status:
      <Typography color={color} component="span">
        {status}
      </Typography>
    </Typography>
  )
}

export const MatchTaskHeader = ({ manualTask, assignTask, onHoldTask, resumeTask }) => {
  const [openOnHoldConfirmDialog, setOpenOnHoldConfirmDialog] = useState(false)

  if (!manualTask.taskId) {
    return null
  }

  const openPrimarySources = () => {
    const { sourceLinks } = manualTask
    sourceLinks.forEach((url) => window.open(url))
  }

  const handlePutOnHoldTask = (reason) => {
    onHoldTask(reason)
    setOpenOnHoldConfirmDialog(false)
  }

  return (
    <Card sx={{ p: 1, m: 1 }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end" direction="row">
              <Grid item>
                <Button onClick={openPrimarySources}>Open Primary data sources</Button>
              </Grid>
              <Grid item>
                <TaskAssignment manualTask={manualTask} assignTask={assignTask} />
              </Grid>
              <Grid item>
                <TaskOnHold manualTask={manualTask} onHoldTask={() => setOpenOnHoldConfirmDialog(true)} resumeTask={resumeTask} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={8} md={7} xs={12}>
            <Typography align="left">Task ID: {manualTask.taskId}</Typography>
            <TaskStatus status={manualTask.status} />
            {manualTask.assignedUser && <Typography align="left">Assigned: {manualTask.assignedUser.email}</Typography>}
            {manualTask.completionUserObj && <Typography align="left">Completed By: {manualTask.completionUserObj.email}</Typography>}
          </Grid>
          <Grid item lg={4} md={5} xs={12}>
            <Typography align="left">Created At: {formatDate({ date: manualTask.taskCreationTime })}</Typography>
            <Typography align="left">Deadline: {formatDate({ date: manualTask.taskSlaTime })}</Typography>
            {manualTask.taskAssignmentTime && <Typography align="left">Assigned At: {formatDate({ date: manualTask.taskAssignmentTime })}</Typography>}
            {manualTask.taskCompletionTime && <Typography align="left">Completed At: {formatDate({ date: manualTask.taskCompletionTime })}</Typography>}
          </Grid>
          <Grid item xs={12}>
            {manualTask.taskTags.map(({ label, value, url }) => url
              ? <Chip key={`task-chip-${label}`} sx={{ margin: '0.25rem' }} color='primary' label={`${label}: ${value}`} component="a" href={url} clickable />
              : <Chip key={`task-chip-${label}`} sx={{ margin: '0.25rem' }} label={`${label}: ${value}`} />,
            )}
          </Grid>
        </Grid>
      </CardContent>
      <OnHoldTaskDialog
       open={openOnHoldConfirmDialog}
       handleClose={() => setOpenOnHoldConfirmDialog(false)}
       handleConfirm={handlePutOnHoldTask}
      />
    </Card>
  )
}

export default MatchTaskHeader
