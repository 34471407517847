import React, { type ReactNode, createContext, useState } from 'react'
import Loader from '../Components/Loader/Loader'

export type LoaderProviderType = {
  showLoader: () => void
  hideLoader: () => void
}

type LoaderContextProvider = {
  children: ReactNode
}

export const LoaderContext = createContext<LoaderProviderType | undefined>(
  undefined,
)

export const LoaderProvider: React.FC<LoaderContextProvider> = ({
  children,
}) => {
  const [ isVisible, setIsVisible ] = useState<boolean>(false)

  const contextValue: LoaderProviderType = {
    showLoader: () => {
      setIsVisible(true)
    },
    hideLoader: () => {
      setIsVisible(false)
    },
  }

  return (
    <LoaderContext.Provider value={ contextValue }>
      <>
        { isVisible && <Loader/> }
        { children }
      </>
    </LoaderContext.Provider>
  )
}
