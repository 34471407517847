type ApiType = {
  getTicket: (taskId: string) => Promise<{ status: string }>
}
export const waitForStatus = async (api: ApiType, taskId: string, expectedStatus: string | string[]): Promise<void> => {
  let attempts = 0

  const checkStatus = async (): Promise<boolean> => {
    const { status } = await api.getTicket(taskId)
    if (Array.isArray(expectedStatus)) {
      return expectedStatus.includes(status)
    }
    return status === expectedStatus
  }

  while (attempts < 10) {
    if (await checkStatus()) {
      return
    }
    attempts++
    await new Promise(resolve => setTimeout(resolve, 500))
  }

  throw new Error('Timeout: Ticket not updated after 2 seconds')
}