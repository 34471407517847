import React from 'react'
import { Button, Box, Card, CardContent, Grid, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useNavigate } from 'react-router-dom'

import { ActionTypes } from '../../../../utils/constants'

import { DataRows } from './DataRows'

const CompletedTicketDetail = ({ manualTask, verificationOrder, profiles, data, completionType }) => {
  const navigate = useNavigate()
  const resubmitTicket = () => {
    navigate(`/taskHandler/${manualTask.taskId}`, {
      state: {
        resubmit: true,
      },
    })
  }

  if (!manualTask.taskId) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  console.log('got verificationOrder proof', verificationOrder.proof)

  return (
    <>
      <CompletedCard completionType={completionType} manualTask={manualTask} verificationOrder={verificationOrder} profiles={profiles} />
      <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
            <Grid item xs={3}>
            </Grid>

            <Grid item xs={4}>
            <Typography align="left">Input</Typography>
            </Grid>
            <Grid item xs={5}>
              <Reasons profiles={profiles} data={data} />
            </Grid>

            <TaskBody
              data={data}
              profiles={profiles}
              verificationOrder={verificationOrder}
              dataUpdater={() => {}}
            />

            {verificationOrder && verificationOrder.proof && (
              <>
                <Grid item xs={3}>
                  Uploaded Proof
                </Grid>
                <Grid item xs={9}>
                  <ProfileProof proof={verificationOrder.proof} />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Box sx={{ mx: 'auto', marginTop: 5 }}>
                <Button variant="contained" onClick={resubmitTicket} >Resubmit to Pending</Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

const CompletedCard = ({ completionType, manualTask, verificationOrder, profiles }) => {
  const { externalComment, externalUrl } = manualTask
  const { rejectionReason } = verificationOrder?.rejectedResult || {}
  return (
  <>
    <Card sx={{ p: 1, m: 1 }}>
      <CardContent>
        <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
          <Grid item xs={3}>
            <Typography align="left">Completion Type:</Typography>
            {rejectionReason && <Typography align="left">Rejection Reason:</Typography>}
            <Typography align="left">External Comment:</Typography>
            <Typography align="left">External URL:</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="left">{completionType}</Typography>
            {rejectionReason && <Typography align="left">{rejectionReason}</Typography>}
            <Typography align="left">{externalComment}</Typography>
            <Typography align="left">{externalUrl}</Typography>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  </>)
}

const ProfileProof = ({ proof }) => {
  if (!proof || !Array.isArray(proof) || proof.length === 0) {
    return null
  }
  return proof.map(({ fileName, documentUrl }) => (
    <img style={{ objectFit: 'cover', width: '100%' }} src={documentUrl} />
  ))
}

const TaskBody = ({
  data,
  profiles,
  verificationOrder,
  dataUpdater,
}) => {
  return <DataRows data={data}
    profiles={profiles}
    verificationOrder={verificationOrder}
    dataUpdater={dataUpdater} />
}

const Reasons = ({ profiles, data }) => {
  const {
    actionType,
    profileIndex,
  } = data
  if (actionType === ActionTypes.selectExisting) {
    console.log('Selected profile', profiles[profileIndex])
    const { rejectReasons = [] } = profiles[profileIndex]
    return rejectReasons.join(', ')
  }
  return null
}

export default CompletedTicketDetail
