import { Box, Grid, Typography, Divider } from '@mui/material'

export const SectionHeader = ({ header }) => (
  <>
    <Grid item xs={3}>
      <Typography variant="h5" align="left" gutterBottom>{header}</Typography>
    </Grid>
    <Grid item xs={9}>
      <Box sx={{ paddingTop: 2 }}>
        <Divider />
      </Box>
    </Grid>
  </>
)
