import React, { useCallback, useEffect } from 'react'
import ReactMarkdown, { uriTransformer } from 'react-markdown'

import mermaid from 'mermaid'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { rehypeCustomAnchor, rehypeCustomMermaid } from './rehypeCustomPlugins'

import './index.css'

// This could be moved to a shared utility file
const startsWithAny = (str, prefixes) => {
  return prefixes.some((prefix) => str.startsWith(prefix))
}

const MarkdownRenderer = ({ markdownText, baseRoute }) => {
  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      theme: 'default',
    })
  }, [])

  useEffect(() => {
    mermaid.contentLoaded()
  }, [markdownText])

  const transformLinkUri = useCallback((href) => {
    if (startsWithAny(href, ['http://', 'https://', 'mailto:', 'tel:'])) {
      return href
    }
    const cleaned = uriTransformer(href)
    const stripped = cleaned.replace(/\.\.\//g, '')
    if (stripped.startsWith('#')) {
      return stripped
    }
    const final = `${baseRoute}/${stripped}`
    return final
  }, [baseRoute])

  return (
    <div className='markdown-renderer'>
      <ReactMarkdown
        children={markdownText}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeCustomAnchor, rehypeCustomMermaid]}
        transformLinkUri={transformLinkUri}
      />
    </div>
  )
}

export default MarkdownRenderer
