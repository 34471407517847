import React, { useMemo } from 'react'
import { Button, Menu, MenuItem, Divider, Typography } from '@mui/material'

import { ActionTypes } from '../../../utils/constants'

export const RecordSelection = ({
  records = [],
  data,
  selectAction,
  recordConfig,
  enabledActions = [],
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelection = (actionType, recordIndex) => {
    handleClose()
    selectAction(actionType, recordIndex)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const primaryOutputs = useMemo(
    () => recordConfig.outputResults.filter(({ isPrimary }) => isPrimary).map(({ fieldName }) => fieldName),
    [recordConfig],
  )

  const buildNameIdentifier = (record) => {
    const { contents } = record
    const primaryContents = Object.keys(contents).filter((name) => primaryOutputs.includes(name)).map((name) => contents[name])
    return primaryContents.join(' ')
  }

  const buildIdentifierFromRecord = (records, recordIndex) =>
    records && records[recordIndex]
      ? buildNameIdentifier(records[recordIndex])
      : 'Invalid selection'

  const { actionType, recordIndex } = data || {}
  const menuLabel = useMemo(() => {
    switch (actionType) {
      case ActionTypes.amendClaims:
        return 'Amend Claims'
      case ActionTypes.createNew:
        return 'Create new Record'
      case ActionTypes.noMatch:
        return 'Return no match'
      case ActionTypes.selectExisting:
        // TODO: Analyze if this is the correct way to build the identifier
        // eslint-disable-next-line no-undef
        return buildIdentifierFromRecord(profiles, profileIndex)
      default:
        return 'Choose Action'
    }
  }, [actionType, recordIndex, records])

  return (
    <>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Typography variant='inherit' noWrap>
          {menuLabel}
        </Typography>
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {records.map((_, idx) => (
          <MenuItem
            key={idx}
            onClick={() => handleSelection(ActionTypes.selectExisting, idx)}
          >
            <Typography variant='inherit' noWrap>
              {buildIdentifierFromRecord(records, idx)}
            </Typography>
          </MenuItem>
        ))}
        {records.length > 0 && <Divider/>}
        {enabledActions.includes(ActionTypes.createNew) && <>
          <MenuItem onClick={() => handleSelection(ActionTypes.createNew)}>
            Create new Record
          </MenuItem>
          <Divider/>
        </>}
        {enabledActions.includes(ActionTypes.noMatch) && <>
          <MenuItem onClick={() => handleSelection(ActionTypes.noMatch)}>
            Return no match
          </MenuItem>
          <Divider />
        </>}
        {enabledActions.includes(ActionTypes.amendClaims) && <>
          <MenuItem onClick={() => handleSelection(ActionTypes.amendClaims)}>
            Amend Claims
          </MenuItem>
        </>}
      </Menu>
    </>
  )
}
