import { STATE_LIST, LICENSE_STATUS, LICENSE_TYPES } from './constants'
import { stateMapper, dateMapper, dropdownMapper } from './fieldRenderer'
const LICENSE_FIELD_LIST = [
  {
    name: 'licenseRegion',
    label: 'License Region',
    fieldType: 'dropdown',
    options: {
      renderer: stateMapper,
      values: STATE_LIST,
    },
  },
  {
    name: 'issuingAuthority',
    label: 'License Board',
    fieldType: 'text',
    options: {},
  },
  {
    name: 'licenseIdentifier',
    label: 'License Number',
    fieldType: 'text',
    options: {},
  },
  {
    name: 'licenseType',
    label: 'License Type',
    fieldType: 'dropdown',
    options: {
      renderer: dropdownMapper(LICENSE_TYPES),
      values: LICENSE_TYPES,
    },
  },
  {
    name: 'licenseTypeAsIs',
    label: 'License Type As Is',
    fieldType: 'text',
    options: {},
  },
  {
    name: 'licenseStatus',
    label: 'License Status',
    fieldType: 'dropdown',
    options: {
      renderer: dropdownMapper(LICENSE_STATUS),
      values: LICENSE_STATUS,
    },
  },
  {
    name: 'licenseStatusAsIs',
    label: 'License Status As Is',
    fieldType: 'text',
    options: {},
  },
  {
    name: 'expirationDate',
    label: 'License Expiration Date',
    fieldType: 'date',
    options: {
      renderer: dateMapper,
    },
  },
  {
    name: 'issueDate',
    label: 'License Issue Date',
    fieldType: 'date',
    options: {
      renderer: dateMapper,
    },
  },
  {
    name: 'licenseeName',
    label: 'Licensee Name',
    fieldType: 'text',
    options: {},
  },
]

export const RECORD_DEFINITIONS = {
  license: {
    fieldList: LICENSE_FIELD_LIST,
  },
}
