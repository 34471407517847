import React, { useMemo } from 'react'
import { InputLabel, Grid, Typography, FormControl, TextField, Select, MenuItem, FormHelperText } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'

import { ActionTypes, STATE_LIST, STATE_MAPPING } from '../../../../utils/constants'
import { SectionHeader } from './SectionHeader'

const defaultVal = (value) =>
  value === undefined || value === null || value === '' ? '-' : value.toString()

const stateMapper = (value) =>
  STATE_MAPPING[value] || defaultVal(value)

const buildDataRowDropdown = (options) => ({
  dataConfig,
  value,
  dataUpdater,
  error,
}) => {
  return <FormControl error={error} fullWidth>
  <InputLabel id={`select-${dataConfig.attribute}-lbl`}>{dataConfig.label}</InputLabel>
  <Select
    id={`select-${dataConfig.attribute}`}
    labelId={`select-${dataConfig.attribute}-lbl`}
    value={value}
    label={dataConfig.label}
    onChange={(evt) => dataUpdater(dataConfig.attribute, evt.target.value) }>
      {options.map(({ value, label }) => <MenuItem value={value}>{label}</MenuItem>)}
  </Select>
  <FormHelperText>{error}</FormHelperText>
</FormControl>
}

const DataRowDatePicker = ({
  dataConfig,
  value,
  dataUpdater,
  error,
}) => <LocalizationProvider dateAdapter={AdapterLuxon}>
  <DesktopDatePicker
    fullWidth
    label={dataConfig.label}
    inputFormat="MM/dd/yyyy"
    value={value === undefined || value === '' ? null : value}
    error={error}
    onChange={(value) => dataUpdater(dataConfig.attribute, value) }
    renderInput={(params) => <TextField {...params} />} />
</LocalizationProvider>

// TODO: Analyze if this is the correct way to define DataRowNoInput
// eslint-disable-next-line
const DataRowNoInput = ({}) => <Typography>- (Not currently supported)</Typography>

const DataRowTextField = ({
  dataConfig,
  value,
  dataUpdater,
  error,
}) => (
  <TextField id={`input-${dataConfig.attribute}`}
    variant="outlined"
    helperText={error}
    error={error}
    label={dataConfig.label}
    size="small"
    value={value}
    onChange={(evt) => dataUpdater(dataConfig.attribute, evt.currentTarget.value)}
    fullWidth />)

const LICENSE_DATA_ROWS = [
  {
    attribute: 'licenseAuthorityRegion',
    label: 'License Authority Region',
    highlightReasons: ['CREDENTIAL_LICENSE_REGION_MISMATCH'],
    inputMapper: stateMapper,
    renderer: buildDataRowDropdown(STATE_LIST),
  },
  {
    attribute: 'licenseAuthorityBoard',
    label: 'License Authority Board',
    highlightReasons: [],
  },
  {
    attribute: 'credentialIdentifier',
    label: 'License #',
    highlightReasons: ['CREDENTIAL_LICENSE_IDENTIFIER_MISMATCH', 'CREDENTIAL_LICENSE_IDENTIFIER_TYPO'],
  },
  {
    attribute: 'licensedEntityType',
    label: 'Licensed Entity Type',
    highlightReasons: ['CREDENTIAL_ENTITY_TYPE_MISMATCH'],
    renderer: buildDataRowDropdown([
      { value: 'business', label: 'Business' },
      { value: 'individual', label: 'Individual' },
    ]),
  },
  {
    attribute: 'licenseType',
    label: 'License Type',
    highlightReasons: ['CREDENTIAL_LICENSE_TYPE_MISMATCH'],
    renderer: buildDataRowDropdown([
      { value: 'homeServices', label: 'Home Services' },
      { value: 'architecture', label: 'Architects' },
      { value: 'professionalBeauty', label: 'Professional Beauty' },
      { value: 'other', label: 'Other' },
    ]),
  },
  {
    attribute: 'licenseTypeAsIs',
    label: 'License Type As Is',
    highlightReasons: [],
  },
  {
    attribute: 'licenseStatus',
    label: 'License Status',
    highlightReasons: ['STATUS_ACTIVE_EXPIRED', 'STATUS_INACTIVE_NONEXPIRED'],
    renderer: buildDataRowDropdown([
      { value: 'Active', label: 'Active' },
      { value: 'Inactive', label: 'Inactive' },
    ]),
  },
  {
    attribute: 'licenseStatusAsIs',
    label: 'License Status As Is',
    highlightReasons: [],
  },
  {
    attribute: 'expiryDate',
    label: 'Expiration Date',
    highlightReasons: [],
    renderer: DataRowDatePicker,
  },
]
const BUSINESS_DATA_ROWS = [
  {
    attribute: 'businessName',
    label: 'Business Name',
    highlightReasons: ['BUSINESS_NAME_TYPO', 'BUSINESS_NAME_MINOR', 'BUSINESS_NAME_MISMATCH'],
  },
  {
    attribute: 'doingBusinessAs',
    label: 'Doing Business As Name',
    highlightReasons: [],
  },
  {
    attribute: 'firstName',
    label: 'First Name',
    highlightReasons: ['FIRST_NAME_TYPO', 'FIRST_NAME_MINOR', 'FIRST_NAME_MISMATCH'],
  },
  {
    attribute: 'middleName',
    label: 'Middle Name',
    highlightReasons: ['MIDDLE_NAME_TYPO', 'MIDDLE_NAME_MINOR', 'MIDDLE_NAME_MISMATCH'],
  },
  {
    attribute: 'lastName',
    label: 'Last Name',
    highlightReasons: ['LAST_NAME_TYPO', 'LAST_NAME_MINOR', 'LAST_NAME_MISMATCH'],
  },
  {
    attribute: 'phoneNumber',
    label: 'Phone Number',
    highlightReasons: [],
  },
  {
    attribute: 'address',
    label: 'Address',
    highlightReasons: [],
    renderer: DataRowNoInput,
    // renderer: DataRowAddress,
  },
]

export const DataRows = ({
  data,
  profiles,
  verificationOrder,
  dataUpdater,
  formErrors,
}) => (
  <>
    <SectionHeader header="License Details" />
    {LICENSE_DATA_ROWS.map((dataConfig, idx) =>
      <DataRow key={`license-data-${idx}`}
        dataConfig={dataConfig}
        data={data}
        profiles={profiles}
        verificationOrder={verificationOrder}
        dataUpdater={dataUpdater}
        formErrors={formErrors}
      />)}

    <SectionHeader header="Business Details" />
    {BUSINESS_DATA_ROWS.map((dataConfig, idx) =>
      <DataRow key={`business-data-${idx}`}
        dataConfig={dataConfig}
        data={data}
        profiles={profiles}
        verificationOrder={verificationOrder}
        dataUpdater={dataUpdater}
        formErrors={formErrors}
      />)}
  </>
)

const DataRow = ({ dataConfig, data, rejectReasons, profiles, verificationOrder, dataUpdater, formErrors }) => {
  const { renderer = DataRowTextField, inputMapper = defaultVal } = dataConfig
  const inputValue = useMemo(() => {
    const {
      actionType,
      profileIndex,
    } = data

    if (actionType === ActionTypes.displayMatched) {
      const profile = verificationOrder.latestResult || {}

      return (
        <Typography align="left">{inputMapper(profile[dataConfig.attribute])}</Typography>
      )
    }

    if (actionType === ActionTypes.createNew) {
      return renderer({
        dataConfig,
        error: formErrors[dataConfig.label],
        value: data[dataConfig.attribute] || '',
        dataUpdater,
      })
    }
    const selectedProfile = profiles[profileIndex] || {}
    const { rejectReasons = [] } = selectedProfile
    const color = dataConfig.highlightReasons.some((v) => rejectReasons.includes(v)) ? 'error.main' : undefined
    return (
      <Typography align="left" sx={{ color }}>{inputMapper(selectedProfile[dataConfig.attribute])}</Typography>
    )
  })

  return (
    <>
      <Grid item xs={3} sx={{ borderRight: 1 }}>
        <Typography align="left">{dataConfig.label}</Typography>
      </Grid>
      <Grid item xs={4} sx={{ borderRight: 1 }}>
        <Typography align="left">{inputMapper(verificationOrder[dataConfig.attribute])}</Typography>
      </Grid>
      <Grid item xs={5}>
        {inputValue}
      </Grid>
    </>
  )
}
