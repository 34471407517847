import React from 'react'
import { Grid, Box, Typography } from '@mui/material'

import { RecordFieldValue } from '../../../../Components/RecordFieldValue'
import { ActionTypes } from '../../../../utils/constants'
import { DataRows } from './RecordDataRows'

const mergeFields = ({ outputResults, inputClaims } = { outputResults: [], inputClaims: [] }) => {
  const foundFields = []
  const fields = [...outputResults, ...inputClaims].reduce((acc, field) => {
    if (foundFields.includes(field.id)) {
      return acc
    }
    foundFields.push(field.id)
    return [...acc, field]
  }, [])
  return fields
}

export const RecordInput = ({
  selectedRecord,
  actionType,
  claimData,
  recordConfig,
  jurisdictionRecord,
}) => {
  if (selectedRecord) {
    const outputResults = mergeFields(recordConfig)
    return (
      <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
        <Grid item xs={3}></Grid>
        <Grid item xs={4}>
          <Typography align='left'>Input</Typography>
        </Grid>
        <Grid item xs={5}>
          Selected
        </Grid>
        <DataRows
          claimData={claimData}
          record={selectedRecord}
          outputResults={[...outputResults, jurisdictionRecord]}
        />
      </Grid>
    )
  }
  const inputFields = (
    <Box>
      Input
      {jurisdictionRecord && (
        <RecordFieldValue
          key={`input-${jurisdictionRecord.fieldName}`}
          name={`input-${jurisdictionRecord.fieldName}`}
          label={jurisdictionRecord.label}
          value={jurisdictionRecord.value}
          fieldType={jurisdictionRecord.dataType}
          options={jurisdictionRecord.options}
          editMode={false}
        />
      )}
      {recordConfig &&
        recordConfig.inputClaims &&
        recordConfig.inputClaims.length &&
        recordConfig.inputClaims.map((field) => (
          <RecordFieldValue
            key={`input-${field.fieldName}`}
            name={`input-${field.fieldName}`}
            label={field.label}
            value={claimData[field.fieldName] || field.value}
            fieldType={field.dataType}
            options={field.options}
            editMode={false}
          />
        ))}
    </Box>
  )
  if (actionType === ActionTypes.createNew) {
    return (
      <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
        <Grid item xs={6}>
          {inputFields}
        </Grid>
        <Grid item xs={6}>
          <Box>
            Outputs
            {recordConfig.outputResults.map((field) => (
              <RecordFieldValue
                key={`output-${field.fieldName}`}
                name={field.fieldName}
                label={field.label}
                fieldType={field.dataType}
                options={field.options}
                editMode={true}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid
      container
      columnSpacing={{ xs: 2 }}
      rowSpacing={{ xs: 1 }}
      justifyContent='center'
      alignItems='center'
    >
      <Grid item xs={8}>
        {inputFields}
      </Grid>
    </Grid>
  )
}
