/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getDocumentation = /* GraphQL */ `
  query GetDocumentation($docPath: String!) {
    getDocumentation(docPath: $docPath)
  }
`;
export const queryManualTaskNotes = /* GraphQL */ `
  query QueryManualTaskNotes($taskId: String!) {
    queryManualTaskNotes(taskId: $taskId) {
      noteId
      taskId
      userId
      user {
        userId
        email
      }
      createdAt
      orderId
      noteType
      noteTitle
      noteText
    }
  }
`;
export const listTickets = /* GraphQL */ `
  query ListTickets($input: ListTicketsInput) {
    listTickets(input: $input) {
      tickets {
        taskId
        taskType
        status
        taskCreationTime
        taskSlaTime
        taskCompletionTime
        externalUrl
        externalComment
        rejectReasons {
          key
          reasons
        }
        completionUser
        completionUserObj {
          userId
          email
        }
        assignedUserId
        assignedUser {
          userId
          email
        }
        allowCompletion
        taskAssignmentTime
        verificationOrder {
          verificationId
          customerId
          entityIdentifier {
            businessName
            firstName
            middleName
            lastName
          }
          businessName
          firstName
          middleName
          lastName
          email
          phoneNumber
          address {
            address1
            address2
            city
            state
            postalCode
            country
          }
          credentialRequest {
            licensedEntityType
            credentialIdentifier
            licenseAuthorityRegion
            licenseType
            additionalInformation
          }
          isMonitoringEnabled
          monitoringExpiryDate
          latestResult {
            profileId
            credentialId
            licensedEntityIdentifier {
              businessName
              firstName
              middleName
              lastName
            }
            licensedEntityType
            credentialIdentifier
            licenseAuthorityRegion
            licenseAuthorityBoard
            licenseType
            licenseTypeAsIs
            licenseStatus
            licenseStatusAsIs
            licenseePhoneNumber
            licenseeAddress {
              address1
              address2
              city
              state
              postalCode
              country
            }
            issueDate
            expiryDate
            suspendedDate
            verificationTime
          }
          rejectedResult {
            rejectionTime
            rejectionType
            rejectionReason
          }
          matchedProfile {
            profileId
            ubis
            businessNames
            phoneNumbers
            emailAddresses
            addresses {
              address1
              address2
              city
              state
              postalCode
              country
            }
            credentials {
              licensedEntityType
              credentialIdentifier
              licenseAuthorityRegion
              licenseType
              licenseTypeAsIs
              lastVerifiedTime
              credentialType
              expiryDate
              profileId
              licenseStatus
              licenseStatusAsIs
              credentialId
              licensedEntityIdentifier {
                businessName
                firstName
                middleName
                lastName
              }
              issueDate
              licenseAuthorityBoard
            }
            proof {
              profileId
              fileName
              contentType
              documentId
              proofTime
              documentUrl
            }
          }
        }
        profiles {
          profileId
          ubis
          businessNames
          phoneNumbers
          emailAddresses
          addresses {
            address1
            address2
            city
            state
            postalCode
            country
          }
          credentials {
            licensedEntityType
            credentialIdentifier
            licenseAuthorityRegion
            licenseType
            licenseTypeAsIs
            lastVerifiedTime
            credentialType
            expiryDate
            profileId
            licenseStatus
            licenseStatusAsIs
            credentialId
            licensedEntityIdentifier {
              businessName
              firstName
              middleName
              lastName
            }
            issueDate
            licenseAuthorityBoard
          }
          proof {
            profileId
            fileName
            contentType
            documentId
            proofTime
            documentUrl
          }
        }
      }
      lastKey
    }
  }
`;
export const getTicket = /* GraphQL */ `
  query GetTicket($taskId: ID!) {
    getTicket(taskId: $taskId) {
      taskId
      taskType
      status
      taskCreationTime
      taskSlaTime
      taskCompletionTime
      externalUrl
      externalComment
      rejectReasons {
        key
        reasons
      }
      completionUser
      completionUserObj {
        userId
        email
      }
      assignedUserId
      assignedUser {
        userId
        email
      }
      allowCompletion
      taskAssignmentTime
      verificationOrder {
        verificationId
        customerId
        entityIdentifier {
          businessName
          firstName
          middleName
          lastName
        }
        businessName
        firstName
        middleName
        lastName
        email
        phoneNumber
        address {
          address1
          address2
          city
          state
          postalCode
          country
        }
        credentialRequest {
          licensedEntityType
          credentialIdentifier
          licenseAuthorityRegion
          licenseType
          additionalInformation
        }
        isMonitoringEnabled
        monitoringExpiryDate
        latestResult {
          profileId
          credentialId
          licensedEntityIdentifier {
            businessName
            firstName
            middleName
            lastName
          }
          licensedEntityType
          credentialIdentifier
          licenseAuthorityRegion
          licenseAuthorityBoard
          licenseType
          licenseTypeAsIs
          licenseStatus
          licenseStatusAsIs
          licenseePhoneNumber
          licenseeAddress {
            address1
            address2
            city
            state
            postalCode
            country
          }
          issueDate
          expiryDate
          suspendedDate
          verificationTime
        }
        rejectedResult {
          rejectionTime
          rejectionType
          rejectionReason
        }
        matchedProfile {
          profileId
          ubis
          businessNames
          phoneNumbers
          emailAddresses
          addresses {
            address1
            address2
            city
            state
            postalCode
            country
          }
          credentials {
            licensedEntityType
            credentialIdentifier
            licenseAuthorityRegion
            licenseType
            licenseTypeAsIs
            lastVerifiedTime
            credentialType
            expiryDate
            profileId
            licenseStatus
            licenseStatusAsIs
            credentialId
            licensedEntityIdentifier {
              businessName
              firstName
              middleName
              lastName
            }
            issueDate
            licenseAuthorityBoard
          }
          proof {
            profileId
            fileName
            contentType
            documentId
            proofTime
            documentUrl
          }
        }
      }
      profiles {
        profileId
        ubis
        businessNames
        phoneNumbers
        emailAddresses
        addresses {
          address1
          address2
          city
          state
          postalCode
          country
        }
        credentials {
          licensedEntityType
          credentialIdentifier
          licenseAuthorityRegion
          licenseType
          licenseTypeAsIs
          lastVerifiedTime
          credentialType
          expiryDate
          profileId
          licenseStatus
          licenseStatusAsIs
          credentialId
          licensedEntityIdentifier {
            businessName
            firstName
            middleName
            lastName
          }
          issueDate
          licenseAuthorityBoard
        }
        proof {
          profileId
          fileName
          contentType
          documentId
          proofTime
          documentUrl
        }
      }
    }
  }
`;
export const getTicketV2 = /* GraphQL */ `
  query GetTicketV2($taskId: ID!) {
    getTicketV2(taskId: $taskId) {
      taskId
      status
      taskType
      taskCreationTime
      taskSlaTime
      taskAssignmentTime
      taskCompletionTime
      assignedUserId
      assignedUser {
        userId
        email
      }
      completionUserId
      completionUser {
        userId
        email
      }
      allowCompletion
      taskTitle
      taskTags {
        label
        value
        url
      }
      sourceLinks
      taskDetails {
        ... on ManualTicketDetail {
          externalUrl
          externalComment
          rejectReasons {
            key
            reasons
          }
          verificationOrder {
            verificationId
            customerId
            entityIdentifier {
              businessName
              firstName
              middleName
              lastName
            }
            businessName
            firstName
            middleName
            lastName
            email
            phoneNumber
            address {
              address1
              address2
              city
              state
              postalCode
              country
            }
            credentialRequest {
              licensedEntityType
              credentialIdentifier
              licenseAuthorityRegion
              licenseType
              additionalInformation
            }
            isMonitoringEnabled
            monitoringExpiryDate
            latestResult {
              profileId
              credentialId
              licensedEntityIdentifier {
                businessName
                firstName
                middleName
                lastName
              }
              licensedEntityType
              credentialIdentifier
              licenseAuthorityRegion
              licenseAuthorityBoard
              licenseType
              licenseTypeAsIs
              licenseStatus
              licenseStatusAsIs
              licenseePhoneNumber
              licenseeAddress {
                address1
                address2
                city
                state
                postalCode
                country
              }
              issueDate
              expiryDate
              suspendedDate
              verificationTime
            }
            rejectedResult {
              rejectionTime
              rejectionType
              rejectionReason
            }
            matchedProfile {
              profileId
              ubis
              businessNames
              phoneNumbers
              emailAddresses
              addresses {
                address1
                address2
                city
                state
                postalCode
                country
              }
              credentials {
                licensedEntityType
                credentialIdentifier
                licenseAuthorityRegion
                licenseType
                licenseTypeAsIs
                lastVerifiedTime
                credentialType
                expiryDate
                profileId
                licenseStatus
                licenseStatusAsIs
                credentialId
                licensedEntityIdentifier {
                  businessName
                  firstName
                  middleName
                  lastName
                }
                issueDate
                licenseAuthorityBoard
              }
              proof {
                profileId
                fileName
                contentType
                documentId
                proofTime
                documentUrl
              }
            }
          }
          profiles {
            profileId
            ubis
            businessNames
            phoneNumbers
            emailAddresses
            addresses {
              address1
              address2
              city
              state
              postalCode
              country
            }
            credentials {
              licensedEntityType
              credentialIdentifier
              licenseAuthorityRegion
              licenseType
              licenseTypeAsIs
              lastVerifiedTime
              credentialType
              expiryDate
              profileId
              licenseStatus
              licenseStatusAsIs
              credentialId
              licensedEntityIdentifier {
                businessName
                firstName
                middleName
                lastName
              }
              issueDate
              licenseAuthorityBoard
            }
            proof {
              profileId
              fileName
              contentType
              documentId
              proofTime
              documentUrl
            }
          }
        }
        ... on MatchInventoryTicketDetail {
          orderId
          vspecId
          vsku
          vskuConfig {
            id
            nameId
            type
            jurisdiction
            inputClaims {
              id
              fieldName
              label
              isRequired
              isPrimary
              dataType
              options {
                values {
                  label
                  value
                }
              }
            }
            outputResults {
              id
              fieldName
              label
              isRequired
              isPrimary
              dataType
              options {
                values {
                  label
                  value
                }
              }
            }
          }
          jurisdiction
          claims {
            name
            value
          }
        }
        ... on CreateRecordTicketDetail {
          rspecId
          recordType
          recordConfig {
            id
            inputClaims {
              id
              fieldName
              label
              isRequired
              isPrimary
              dataType
              options {
                values {
                  label
                  value
                }
              }
            }
            outputResults {
              id
              fieldName
              label
              isRequired
              isPrimary
              dataType
              options {
                values {
                  label
                  value
                }
              }
            }
          }
          jurisdiction
          claims {
            name
            value
          }
          orderId
          vspecId
          professionalId
          itemIndex
        }
        ... on MatchRecordTicketDetail {
          rspecId
          recordType
          recordConfig {
            id
            inputClaims {
              id
              fieldName
              label
              isRequired
              isPrimary
              dataType
              options {
                values {
                  label
                  value
                }
              }
            }
            outputResults {
              id
              fieldName
              label
              isRequired
              isPrimary
              dataType
              options {
                values {
                  label
                  value
                }
              }
            }
          }
          jurisdiction
          claims {
            name
            value
          }
          orderId
          vspecId
          professionalId
          itemIndex
          candidates {
            recordId
            contents {
              name
              value
            }
          }
          invRequestId
        }
      }
    }
  }
`;
export const listTicketsV2 = /* GraphQL */ `
  query ListTicketsV2($input: ListTicketsInput) {
    listTicketsV2(input: $input) {
      tickets {
        taskId
        status
        taskType
        taskCreationTime
        taskSlaTime
        taskAssignmentTime
        taskCompletionTime
        assignedUserId
        assignedUser {
          userId
          email
        }
        completionUserId
        completionUser {
          userId
          email
        }
        allowCompletion
        taskTitle
        taskTags {
          label
          value
          url
        }
        sourceLinks
        taskDetails {
          ... on ManualTicketDetail {
            externalUrl
            externalComment
            rejectReasons {
              key
              reasons
            }
            verificationOrder {
              verificationId
              customerId
              entityIdentifier {
                businessName
                firstName
                middleName
                lastName
              }
              businessName
              firstName
              middleName
              lastName
              email
              phoneNumber
              address {
                address1
                address2
                city
                state
                postalCode
                country
              }
              credentialRequest {
                licensedEntityType
                credentialIdentifier
                licenseAuthorityRegion
                licenseType
                additionalInformation
              }
              isMonitoringEnabled
              monitoringExpiryDate
              latestResult {
                profileId
                credentialId
                licensedEntityIdentifier {
                  businessName
                  firstName
                  middleName
                  lastName
                }
                licensedEntityType
                credentialIdentifier
                licenseAuthorityRegion
                licenseAuthorityBoard
                licenseType
                licenseTypeAsIs
                licenseStatus
                licenseStatusAsIs
                licenseePhoneNumber
                licenseeAddress {
                  address1
                  address2
                  city
                  state
                  postalCode
                  country
                }
                issueDate
                expiryDate
                suspendedDate
                verificationTime
              }
              rejectedResult {
                rejectionTime
                rejectionType
                rejectionReason
              }
              matchedProfile {
                profileId
                ubis
                businessNames
                phoneNumbers
                emailAddresses
                addresses {
                  address1
                  address2
                  city
                  state
                  postalCode
                  country
                }
                credentials {
                  licensedEntityType
                  credentialIdentifier
                  licenseAuthorityRegion
                  licenseType
                  licenseTypeAsIs
                  lastVerifiedTime
                  credentialType
                  expiryDate
                  profileId
                  licenseStatus
                  licenseStatusAsIs
                  credentialId
                  licensedEntityIdentifier {
                    businessName
                    firstName
                    middleName
                    lastName
                  }
                  issueDate
                  licenseAuthorityBoard
                }
                proof {
                  profileId
                  fileName
                  contentType
                  documentId
                  proofTime
                  documentUrl
                }
              }
            }
            profiles {
              profileId
              ubis
              businessNames
              phoneNumbers
              emailAddresses
              addresses {
                address1
                address2
                city
                state
                postalCode
                country
              }
              credentials {
                licensedEntityType
                credentialIdentifier
                licenseAuthorityRegion
                licenseType
                licenseTypeAsIs
                lastVerifiedTime
                credentialType
                expiryDate
                profileId
                licenseStatus
                licenseStatusAsIs
                credentialId
                licensedEntityIdentifier {
                  businessName
                  firstName
                  middleName
                  lastName
                }
                issueDate
                licenseAuthorityBoard
              }
              proof {
                profileId
                fileName
                contentType
                documentId
                proofTime
                documentUrl
              }
            }
          }
          ... on MatchInventoryTicketDetail {
            orderId
            vspecId
            vsku
            vskuConfig {
              id
              nameId
              type
              jurisdiction
              inputClaims {
                id
                fieldName
                label
                isRequired
                isPrimary
                dataType
                options {
                  values {
                    label
                    value
                  }
                }
              }
              outputResults {
                id
                fieldName
                label
                isRequired
                isPrimary
                dataType
                options {
                  values {
                    label
                    value
                  }
                }
              }
            }
            jurisdiction
            claims {
              name
              value
            }
          }
          ... on CreateRecordTicketDetail {
            rspecId
            recordType
            recordConfig {
              id
              inputClaims {
                id
                fieldName
                label
                isRequired
                isPrimary
                dataType
                options {
                  values {
                    label
                    value
                  }
                }
              }
              outputResults {
                id
                fieldName
                label
                isRequired
                isPrimary
                dataType
                options {
                  values {
                    label
                    value
                  }
                }
              }
            }
            jurisdiction
            claims {
              name
              value
            }
            orderId
            vspecId
            professionalId
            itemIndex
          }
          ... on MatchRecordTicketDetail {
            rspecId
            recordType
            recordConfig {
              id
              inputClaims {
                id
                fieldName
                label
                isRequired
                isPrimary
                dataType
                options {
                  values {
                    label
                    value
                  }
                }
              }
              outputResults {
                id
                fieldName
                label
                isRequired
                isPrimary
                dataType
                options {
                  values {
                    label
                    value
                  }
                }
              }
            }
            jurisdiction
            claims {
              name
              value
            }
            orderId
            vspecId
            professionalId
            itemIndex
            candidates {
              recordId
              contents {
                name
                value
              }
            }
            invRequestId
          }
        }
      }
      lastKey
    }
  }
`;
export const getTools = /* GraphQL */ `
  query GetTools {
    getTools {
      tools {
        name
        description
        arn
        executionModes
        arguments {
          name
          description
          type
          required
          defaultValue {
            value
            type
          }
        }
      }
    }
  }
`;
