/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const putManualTaskNote = /* GraphQL */ `
  mutation PutManualTaskNote($input: PutManualTaskNoteInput!) {
    putManualTaskNote(input: $input) {
      noteId
      taskId
      userId
      user {
        userId
        email
      }
      createdAt
      orderId
      noteType
      noteTitle
      noteText
    }
  }
`;
export const createProfileMatch = /* GraphQL */ `
  mutation CreateProfileMatch($input: CreateProfileInput!) {
    createProfileMatch(input: $input) {
      result
      profileId
      credentialId
      uploadedProofs {
        fileName
        contentType
        documentId
        uploadUrl
      }
    }
  }
`;
export const createMatch = /* GraphQL */ `
  mutation CreateMatch($input: CreateMatchInput!) {
    createMatch(input: $input) {
      result
      profileId
      credentialId
      uploadedProofs {
        fileName
        contentType
        documentId
        uploadUrl
      }
    }
  }
`;
export const createNoMatch = /* GraphQL */ `
  mutation CreateNoMatch($input: CreateNoMatchInput!) {
    createNoMatch(input: $input) {
      result
      profileId
      credentialId
      uploadedProofs {
        fileName
        contentType
        documentId
        uploadUrl
      }
    }
  }
`;
export const assignTask = /* GraphQL */ `
  mutation AssignTask($taskId: ID!) {
    assignTask(taskId: $taskId)
  }
`;
export const unassignTask = /* GraphQL */ `
  mutation UnassignTask($taskId: ID!) {
    unassignTask(taskId: $taskId)
  }
`;
export const completeProofUpload = /* GraphQL */ `
  mutation CompleteProofUpload($input: CompleteProofInput!) {
    completeProofUpload(input: $input)
  }
`;
export const submitClaimsAmended = /* GraphQL */ `
  mutation SubmitClaimsAmended($input: SubmitClaimsAmended!) {
    submitClaimsAmended(input: $input) {
      result
      uploadedProofs {
        fileName
        contentType
        documentId
        uploadUrl
      }
    }
  }
`;
export const submitMatchInventory = /* GraphQL */ `
  mutation SubmitMatchInventory($input: MatchInventoryResult!) {
    submitMatchInventory(input: $input) {
      result
      profileId
      credentialId
      uploadedProofs {
        fileName
        contentType
        documentId
        uploadUrl
      }
    }
  }
`;
export const submitCreateRecord = /* GraphQL */ `
  mutation SubmitCreateRecord($input: CreateRecordResult!) {
    submitCreateRecord(input: $input) {
      result
      profileId
      credentialId
      uploadedProofs {
        fileName
        contentType
        documentId
        uploadUrl
      }
    }
  }
`;
export const submitMatchRecord = /* GraphQL */ `
  mutation SubmitMatchRecord($input: MatchRecordResult!) {
    submitMatchRecord(input: $input) {
      result
      profileId
      credentialId
      uploadedProofs {
        fileName
        contentType
        documentId
        uploadUrl
      }
    }
  }
`;
export const completeProofUploadInventory = /* GraphQL */ `
  mutation CompleteProofUploadInventory($input: CompletedProofUpload!) {
    completeProofUploadInventory(input: $input)
  }
`;
export const putOnHoldTask = /* GraphQL */ `
  mutation PutOnHoldTask($taskId: ID!, $userText: String) {
    putOnHoldTask(taskId: $taskId, userText: $userText)
  }
`;
export const resumeTask = /* GraphQL */ `
  mutation ResumeTask($taskId: ID!) {
    resumeTask(taskId: $taskId)
  }
`;
export const executeTool = /* GraphQL */ `
  mutation ExecuteTool($input: ExecuteToolInput!) {
    executeTool(input: $input) {
      resultType
      results
      message
      resumeToken
    }
  }
`;
