import React, { useMemo } from 'react'
import { Button, Box, Card, CardContent, Grid, Typography, TextField, Menu, MenuItem, Divider } from '@mui/material'
import ProofUploader from '../../Shared/ProofUploader'
import CircularProgress from '@mui/material/CircularProgress'

import { ActionTypes } from '../../../../utils/constants'

import { DataRows } from './DataRows'
import { SectionHeader } from './SectionHeader'

const PendingTicketDetail = ({
  manualTask, verificationOrder, profiles, selectAction, data,
  dataUpdater, formErrors, submitAction, submitting, setData, setFiles, files,
}) => {
  if (!manualTask.taskId) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }
  const { externalComment, externalUrl } = data

  return (
    <Card sx={{ p: 1, m: 1 }}>
      <CardContent>
        <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
          <Grid item xs={12}>
            <ProfileSelection profiles={profiles} selectAction={selectAction} data={data} />
          </Grid>
          <Grid item xs={3}>

          </Grid>
          <Grid item xs={4}>
          <Typography align="left">Input</Typography>
          </Grid>
          <Grid item xs={5}>
            Selected
            {/* <Reasons profiles={profiles} data={data} /> */}
          </Grid>

          <TaskBody
            data={data}
            profiles={profiles}
            verificationOrder={verificationOrder}
            dataUpdater={dataUpdater}
            formErrors={formErrors}
          />

          <SectionHeader header="Additional Information" />
          <Grid item xs={3}>
            <Typography align="left">External Comment</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField id="externalComment"
              label="Optional comments"
              variant="outlined"
              minRows={3}
              value={externalComment}
              onChange={(evt) => dataUpdater('externalComment', evt.currentTarget.value)}
              multiline
              fullWidth />
          </Grid>
          <Grid item xs={3}>
            <Typography align="left">External URL</Typography>
          </Grid>
          <Grid item xs={9} >
            <TextField id="externalUrl"
              label="Type url"
              variant="outlined"
              value={externalUrl}
              onChange={(evt) => dataUpdater('externalUrl', evt.currentTarget.value)}
              fullWidth />
          </Grid>
          {data.actionType === ActionTypes.createNew && (
            <>
              <Grid item xs={3}>
                <Typography align="left">Attach Proof</Typography>
              </Grid>
              <Grid item xs={9}>
                <ProofUploader files={files} setFiles={setFiles} />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Box sx={{ mx: 'auto' }}>
              {manualTask.allowCompletion && <Button variant="contained" onClick={submitAction} >
                {submitting ? <CircularProgress color="secondary" size={20} /> : 'Submit findings'}
              </Button>}
              <LocalFillButton data={data} setData={setData} />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

const LocalFillButton = ({ data, setData }) => {
  if (!window.location.href.startsWith('http://localhost')) {
    return null
  }
  if (data.actionType !== ActionTypes.createNew) {
    return null
  }
  const fillData = () => {
    console.log('Filling data', data)
    setData({
      ...data,
      licenseAuthorityRegion: 'TX',
      licenseAuthorityBoard: 'TX Fake Board',
      credentialIdentifier: '123456',
      licensedEntityType: 'individual',
      licenseType: 'professionalBeauty',
      licenseTypeAsIs: 'Cosmetologist',
      licenseStatus: 'Active',
      licenseStatusAsIs: 'Active',
      firstName: 'John',
      lastName: 'Smith',
    })
  }
  return (
    <Button variant="contained" onClick={fillData} >
      Fill Fake Data
    </Button>
  )
}

const TaskBody = ({
  data,
  profiles,
  verificationOrder,
  dataUpdater,
  formErrors,
}) => {
  if (data.actionType === ActionTypes.noMatch) {
    return (
      <>
        <SectionHeader header="Provide reason for not found" />
          <Grid item xs={3}>
            <Typography align="left">Not Found</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField id="noMatchReason"
              label="Reason"
              variant="outlined"
              helperText={formErrors['Not Found']}
              error={!!formErrors['Not Found']}
              minRows={3}
              value={data.noMatchReason}
              onChange={(evt) => dataUpdater('noMatchReason', evt.currentTarget.value)}
              multiline
              fullWidth />
          </Grid>
      </>
    )
  }
  return <DataRows data={data}
    profiles={profiles}
    verificationOrder={verificationOrder}
    dataUpdater={dataUpdater}
    formErrors={formErrors} />
}

// const Reasons = ({ profiles, data }) => {
//   const {
//     actionType,
//     profileIndex,
//   } = data
//   if (actionType === ActionTypes.selectExisting) {
//     console.log('Selected profile', profiles[profileIndex])
//     const { rejectReasons = [] } = profiles[profileIndex]
//     return rejectReasons.join(', ')
//   }
//   return null
// }

const ProfileSelection = ({ profiles, data, selectAction }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelection = (actionType, profileIndex) => {
    handleClose()
    selectAction(actionType, profileIndex)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const buildNameIdentifier = (profile) => {
    const { credentialIdentifier, businessName, firstName, lastName, rejectReasons = [] } = profile
    const name = [businessName, [firstName, lastName].filter((v) => v).join(' ').trim()].filter((v) => v).join(' / ')
    const reasonCount = `${rejectReasons.join(', ')}`
    const joinedIdentifier = [credentialIdentifier, name, reasonCount].filter((val) => val).join(' - ')
    return `License ${joinedIdentifier}`
  }

  const buildIdentifierFromProfile = (profiles, profileIndex) => (profiles && profiles[profileIndex])
    ? buildNameIdentifier(profiles[profileIndex])
    : 'Invalid selection'

  const {
    actionType,
    profileIndex,
  } = data
  const menuLabel = useMemo(() => {
    switch (actionType) {
      case ActionTypes.createNew:
        return 'Create new Profile'
      case ActionTypes.noMatch:
        return 'Return no match'
      case ActionTypes.selectExisting:
        return buildIdentifierFromProfile(profiles, profileIndex)
      default:
        return 'Choose Action'
    }
  }, [actionType, profileIndex, profiles])

  return (
  <>
    <Button
      id="basic-button"
      aria-controls={open ? 'basic-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}>
      <Typography variant="inherit" noWrap>
        {menuLabel}
      </Typography>
    </Button>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {profiles.map((_, idx) =>
        <MenuItem key={idx} onClick={() => handleSelection(ActionTypes.selectExisting, idx)}>
          <Typography variant="inherit" noWrap>{buildIdentifierFromProfile(profiles, idx)}</Typography>
        </MenuItem>,
      )}
      {profiles.length > 0 && <Divider />}
      <MenuItem onClick={() => handleSelection(ActionTypes.createNew)}>Create new Profile</MenuItem>

      <Divider />
      <MenuItem onClick={() => handleSelection(ActionTypes.noMatch)}>Return no match</MenuItem>
    </Menu>
  </>
  )
}

export default PendingTicketDetail
