import React, { useState, useContext, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import ApiContext from '../../Services/apiService'
import { Typography, Stack, Box, CircularProgress } from '@mui/material'
import { DisplayTool } from './DisplayTool'
import { ToolOutputDialog } from './ToolOutputDialog'

const ListTools = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [tools, setTools] = useState([])
  const [loadingTool, setLoadingTools] = useState(false)
  const [loading, setLoading] = useState(false)

  const [showDialog, setShowDialog] = useState(false)
  const api = useContext(ApiContext)
  const [output, setOutput] = useState({})
  const [inputs, setInputs] = useState({})
  const [executingTool, setExecutingTool] = useState({ name: '' })

  const handleClose = () => {
    setSearchParams({})
    setShowDialog(false)
  }
  const triggerTool = async (tool, input) => {
    setOutput({})
    setInputs(Object.fromEntries(input.arguments.map(({ name, value }) => ([name, value]))))
    setExecutingTool(tool)
    setShowDialog(true)
    setLoading(true)
    console.log('submitting tool - executeTool', input)
    try {
      const result = await api.executeTool(input)
      console.log('result', result)
      setLoading(false)
      setOutput(result)
    } catch (err) {
      console.error('error executing tool', err.errors[0].message)
      setLoading(false)
      setOutput({ results: JSON.stringify({ errorMessage: err.errors[0].message }) })
    }
  }

  const executeTool = async (tool, input) => {
    console.log('triggering tool', tool, input)
    setSearchParams({ tool: tool.name, mode: input.mode, arguments: btoa(JSON.stringify(input.arguments)) })
  }

  useEffect(() => {
    const fetchTools = async () => {
      setLoadingTools(true)
      setTools([])
      const { tools: newTools } = await api.getTools()
      setTools(newTools)
      setLoadingTools(false)
    }
    fetchTools()
  }, [api])

  useEffect(() => {
    if (searchParams.get('tool')) {
      const toolName = searchParams.get('tool')
      const args = JSON.parse(atob(searchParams.get('arguments')) || '[]')
      const mode = searchParams.get('mode') || 'query'
      const tool = tools.find((tool) => tool.name === toolName)
      if (tool) {
        console.log('executing from search params', tool, mode, args)
        triggerTool(tool, { toolArn: tool.arn, mode, arguments: args })
      }
    }
  }, [searchParams, tools])

  return (
    <>
      <Typography variant='h1'>Tools</Typography>
      <Typography variant='body'>This is a collection of tools that can be used to assist in troubleshooting or resolving problems</Typography>
      <Box>
        {loadingTool && <CircularProgress />}
        <Stack spacing={2} paddingTop={2}>
          {tools.map((tool) => <DisplayTool tool={tool} executeTool={executeTool} />)}
        </Stack>
      </Box>
    <ToolOutputDialog
      tool={executingTool}
      initialInputs={inputs}
      output={output}
      open={showDialog}
      handleClose={handleClose}
      loading={loading}
      executeTool={executeTool} />
    </>
  )
}

export default ListTools
