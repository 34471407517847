
import React from 'react'
import { IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'

type CopyButtonProps = {
  value: string
}

type PasteButtonProps = {
  value: string
  setValue: (value: string) => void
}


const copyToClipboard = async (value: string): Promise<void> => {
  await navigator.clipboard.writeText(value)
}

const copyFromClipboard = async (): Promise<string> => {
  return navigator.clipboard.readText()
}

export const CopyButton: React.FC<CopyButtonProps> = ({ value }) => {
  return (
    <IconButton onClick={() => { copyToClipboard(value).catch(console.error) }}>
      <ContentCopyIcon />
    </IconButton>
  )
}

export const PasteButton: React.FC<PasteButtonProps> = ({ setValue }) => {
  return (
    <IconButton onClick={() => {
      copyFromClipboard().then(text => {
        setValue(text)
      }).catch(error => {
        console.error('Failed to read clipboard contents: ', error)
      })
    }}>
      <ContentPasteIcon />
    </IconButton>
  )
}
