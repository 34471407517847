import React, { useContext, useState, useMemo } from 'react'
import {
  Grid,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Button,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

import ApiContext from '../../../../Services/apiService'
import { STATE_LIST, ActionTypes } from '../../../../utils/constants'
import { RetriggerVerificationFooter } from '../../Shared/RetriggerVerificationFooter'
import AmendClaimsForm from '../../Shared/AmendClaimsForm'
import ProofUploader from '../../Shared/ProofUploader'
import { RecordSelection } from '../../Shared/RecordSelection'
import { RecordInput } from './RecordInput'
import { milliSecondsFromDateString } from '../../../../utils/formatDate'
import { formatAddress } from '../../../../utils/formatAddress'

const findInnerError = (err) => {
  // This is a regular JS exception
  if (err?.message) {
    return err.message
  }
  // This is a GraphQL error message array
  if (Array.isArray(err?.errors)) {
    const [{ message }] = err.errors
    return message
  }
}

const MatchRecord = ({ manualTask, setErrorDialog }) => {
  const navigator = useNavigate()
  const api = useContext(ApiContext)
  const [data, setData] = useState({})
  const [selectedRecord, setSelectedRecord] = useState(undefined)
  const [submitting, setSubmitting] = useState(false)
  const [files, setFiles] = useState([])

  const completed = manualTask.status === 'COMPLETED'

  const {
    claimData,
    jurisdictionRecord,
    candidates,
    recordConfig,
  } = useMemo(() => {
    const claimData = Object.fromEntries(
      manualTask.taskDetails.claims.map(({ name, value }) => {
        if (name === 'businessAddress') {
          return [name, formatAddress(value)]
        }
        return [name, value]
      }),
      manualTask.taskDetails.claims.map(({ name, value }) => [name, value]),
    )
    const { recordConfig, jurisdiction } = manualTask.taskDetails
    const jurisdictionValue = jurisdiction.split('.')[1] || jurisdiction
    const jurisdictionRecord = {
      dataType: 'enum',
      fieldName: 'licenseRegion',
      label: 'License Region',
      options: {
        values: STATE_LIST,
      },
      value: STATE_LIST.find(({ value }) => value === jurisdictionValue)?.value,
    }
    const candidates = manualTask.taskDetails.candidates.map(
      ({ recordId, contents }) => ({
        recordId,
        contents: contents.reduce((acc, actual) => ({ ...acc, [actual.name]: actual.value }), {}),
      }),
    )
    return {
      claimData,
      jurisdictionRecord,
      candidates,
      recordConfig,
    }
  }, [manualTask])

  const selectAction = useMemo(() => {
    return (actionType, recordIndex) => {
      setSelectedRecord(
        recordIndex !== undefined ? candidates[recordIndex] : undefined,
      )
      setData({
        actionType,
        recordIndex,
      })
    }
  }, [data, candidates])

  const hasSelectedRecord = useMemo(
    () => data.actionType,
    [data, selectedRecord],
  )

  const handleSubmit = useMemo(() => {
    const mapOutputValue = ({ name, value }) => {
      const resultConfig = recordConfig.outputResults.filter(({ fieldName }) => fieldName === name)[0]
      if (resultConfig && resultConfig.dataType === 'date') {
        return milliSecondsFromDateString({ dateString: value })
      }
      return value
    }
    return async (evt) => {
      evt.preventDefault()
      const formatFormData = (formData) => ({ contents: [...formData.entries()].map(([name, value]) => ({ name, value: mapOutputValue({ name, value }) })).filter(({ value }) => value !== '') })
      const formatRecord = (recordData) => ({ ...recordData, contents: Object.entries(recordData.contents).map(([name, value]) => ({ name, value })).filter(({ value }) => value !== '') })
      const notMatched = data.actionType === ActionTypes.noMatch
      const result = notMatched ? undefined : data.actionType === ActionTypes.createNew ? formatFormData(new FormData(evt.target)) : formatRecord(selectedRecord)
      const uploadedProof = Object.values(files).map(({ fileName, contentType }) => ({ fileName, contentType }))
      const input = {
        taskId: manualTask.taskId,
        notMatched,
        result,
        uploadedProof,
      }
      setSubmitting(true)
      try {
        const { uploadedProofs } = await api.submitMatchRecord(input)
        if (uploadedProofs && uploadedProofs.length) {
          await Promise.all(
            uploadedProofs.map(({ uploadUrl, fileName, contentType, documentId }) => (api.uploadDocument({ uploadUrl, fileName, contentType, documentId, data: files[fileName].file }))))
          await api.completeProofUploadInventory({ taskId: input.taskId, foundResults: !!input.result, results: input.result?.contents, completedProof: uploadedProofs.map(({ documentId, contentType, fileName }) => ({ documentId, contentType, fileName })) })
        }
        setSubmitting(false)
        navigator('/home')
      } catch (err) {
        setSubmitting(false)
        console.log('got error', err)
        const inner = findInnerError(err)
        setErrorDialog({
          show: true,
          errorMessage: `There was an error while submitting the form. Please try again or contact #oncall - ${inner}`,
        })
      }
    }
  }, [api, navigator, data, manualTask, files, selectedRecord, recordConfig])

  if (data?.actionType === ActionTypes.amendClaims) {
    return (
      <AmendClaimsForm
       manualTask={manualTask}
       setErrorDialog={setErrorDialog}
       onSubmitSuccess={() => navigator('/home')}
       onCancel={() => selectAction(undefined, undefined)}
      />
    )
  }

  return (
    <Box
      component='form'
      onSubmit={(evt) => {
        handleSubmit(evt)
      }}
    >
      <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
            <Grid item xs={12}>
              <RecordSelection
                records={candidates}
                selectAction={selectAction}
                recordConfig={recordConfig}
                enabledActions={[ActionTypes.createNew, ActionTypes.noMatch, ActionTypes.amendClaims]}
              />
            </Grid>
          </Grid>
          <RecordInput
            claimData={claimData}
            selectedRecord={selectedRecord}
            recordConfig={recordConfig}
            actionType={data?.actionType}
            jurisdictionRecord={jurisdictionRecord}
          />
        </CardContent>
      </Card>
      {(selectedRecord || data?.actionType === ActionTypes.createNew) && (
        <Card sx={{ p: 1, m: 1 }}>
          <CardContent>
            <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
              <Grid item xs={3}>
                <Typography align='left'>Attach Proof</Typography>
              </Grid>
              <Grid item xs={9}>
                <ProofUploader files={files} setFiles={setFiles} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      <RetriggerVerificationFooter completed={completed} taskDetails={manualTask?.taskDetails} />
      {!completed && <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
            <Grid item xs={4} />

            <Grid item xs={4}>
              <Button
                disabled={!hasSelectedRecord || submitting}
                variant='contained'
                fullWidth
                type='submit'
              >
                {submitting
                  ? (
                  <CircularProgress color='secondary' size={20} />
                    )
                  : data?.actionType === ActionTypes.noMatch
                    ? (
                        'Submit not found'
                      )
                    : (
                        'Submit findings'
                      )}
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </CardContent>
      </Card>}
    </Box>
  )
}

export default MatchRecord
