import React from 'react'
import { useInView } from 'react-cool-inview'
import { Box, Container, InputLabel, Select, MenuItem, Grid, FormControl, FormControlLabel, Checkbox, Switch, Button, LinearProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TicketCard from '../../Components/TicketCard'
import { useLoader } from '../../Services/useLoader'

const TicketList = ({ tickets, refreshTickets, filter, setFilter, isRefreshing, scrollList, pageFetching, lastKey, assignTicket }) => {
  const navigate = useNavigate()
  const { showLoader, hideLoader } = useLoader()

  const openTicket = ({ val }) => {
    navigate(`/taskHandler/${val.taskId}`)
  }
  const startTicket = async ({ val }) => {
    showLoader()
    await assignTicket({ taskId: val.taskId })
    navigate(`/taskHandler/${val.taskId}`)
    hideLoader()
  }

  if (!tickets) {
    return <div className="tasks">
      <h1>Loading</h1>
    </div>
  }

  return (
    <Container maxWidth="xl" sx={{ bgcolor: '#f8f8f8' }}>
      <FilterSection
        filter={filter}
        setFilter={setFilter}
        refreshTickets={refreshTickets}
        isRefreshing={isRefreshing} />
      <Box sx={{ width: '100%', height: '4px' }}>
        {isRefreshing && <LinearProgress />}
      </Box>
      {tickets.length === 0 && !isRefreshing &&
        <div className="tasks">
          <h1>No tickets found!</h1>
        </div>
      }

      {tickets.map((val, index) => (
        <TicketCard key={index} onPressed={() => openTicket({ val })} onStart={() => startTicket({ val })} ticket={val}/>
      ))}
      <Scroller scrollList={scrollList} lastKey={lastKey} pageFetching={pageFetching} />
    </Container>
  )
}
const Scroller = ({ scrollList, lastKey, pageFetching }) => {
  const { observe } = useInView({
    onEnter: () => {
      scrollList()
    },
  })
  return (
    <>
      <div ref={observe} />
      {pageFetching && <LinearProgress />}
      {lastKey && (
        <Box sx={{ textAlign: 'center' }}><Button onClick={scrollList}>Load More</Button></Box>
      )}
    </>
  )
}

const FilterSection = ({ filter, setFilter, refreshTickets, isRefreshing }) => {
  const { status, reverseOrder, liveRefresh } = filter
  const handleStatus = (val) => {
    setFilter({ ...filter, status: val.target.value })
  }
  const handleReverse = (val) => {
    setFilter({ ...filter, reverseOrder: val.target.checked })
  }
  const handleLive = (val) => {
    setFilter({ ...filter, liveRefresh: val.target.checked })
  }
  return (
    <div className="filters">
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormControl fullWidth>
          <InputLabel id="ticket-status-lbl">Ticket Status</InputLabel>
            <Select
              labelId="ticket-status-lbl"
              id="ticket-status"
              value={status}
              label="Ticket Status"
              onChange={handleStatus}>
              <MenuItem value="PENDING">Pending</MenuItem>
              <MenuItem value="ASSIGNED">Assigned</MenuItem>
              <MenuItem value="ON_HOLD">On Hold</MenuItem>
              <MenuItem value="COMPLETED">Completed</MenuItem>
              <MenuItem value="CANCELLED">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel control={<Checkbox checked={reverseOrder} onChange={handleReverse} />} label="Oldest First" />
        </Grid>

        <Grid item xs={2}>
          <FormControlLabel control={<Switch checked={liveRefresh} onChange={handleLive} />} label="Refresh Automatically" />
        </Grid>
        <Grid item xs={2}>
          <Button variant="outlined" onClick={refreshTickets}>Refresh</Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default TicketList
