import React, { useCallback, useContext, useMemo } from 'react'
import { Grid, Card, CardContent, Button, Box, CircularProgress, Typography } from '@mui/material'
import { RecordFieldValue } from '../../../Components/RecordFieldValue'
import { STATE_LIST } from '../../../utils/constants'
import { milliSecondsFromDateString } from '../../../utils/formatDate'
import ApiContext from '../../../Services/apiService'
import ProofUploader from './ProofUploader'

const findInnerError = (err) => {
  if (err?.message) {
    return err.message
  }
  if (Array.isArray(err?.errors)) {
    const [{ message }] = err.errors
    return message
  }
}

const mapClaimsValue = ({ name, value, recordConfig }) => {
  const resultConfig = recordConfig.inputClaims.find(({ fieldName }) => fieldName === name)
  if (resultConfig && resultConfig.dataType === 'date') {
    return milliSecondsFromDateString({ dateString: value })
  }
  return value
}

const AmendClaimsForm = ({ manualTask, setErrorDialog, onSubmitSuccess, onCancel }) => {
  const api = useContext(ApiContext)
  const [submitting, setSubmitting] = React.useState(false)
  const [formChanged, setFormChanged] = React.useState(false)
  const [files, setFiles] = React.useState([])
  const recordConfig = useMemo(() => manualTask?.taskDetails?.recordConfig, [manualTask])

  const { claimData, jurisdictionRecord } = useMemo(() => {
    const claimData = Object.fromEntries(manualTask.taskDetails.claims.map(({ name, value }) => ([name, value])))
    const jurisdictionValue = manualTask.taskDetails.jurisdiction.split('.')[1] || manualTask.taskDetails.jurisdiction
    const jurisdictionRecord = {
      dataType: 'enum',
      fieldName: 'jurisdiction',
      label: 'License Region',
      options: {
        values: STATE_LIST,
      },
      value: STATE_LIST.find(({ value }) => value === jurisdictionValue)?.value,
    }
    return { claimData, jurisdictionRecord }
  }, [manualTask])

  const handleSubmit = useCallback(async (evt) => {
    evt.preventDefault()
    setSubmitting(true)

    const entries = new FormData(evt.target).entries()
    const values = [...entries].map(([name, value]) => ({ name, value }))

    const claims = values
      .map(({ name, value }) => ({ name, value: mapClaimsValue({ name, value, recordConfig }) }))
      .filter(({ name, value }) => value !== '' && claimData[name] !== value) // Only keep modified claims

    if (claims.length === 0) {
      setSubmitting(false)
      setErrorDialog({
        show: true,
        errorMessage: 'The claims were not changed. Please fill in the form and try again.',
      })
      return
    }

    const uploadedProof = Object.values(files).map(({ fileName, contentType }) => ({ fileName, contentType }))

    const request = {
      taskId: manualTask.taskId,
      claims,
      uploadedProof,
    }

    try {
      const { uploadedProofs } = await api.submitClaimsAmended(request)
      if (uploadedProofs && uploadedProofs.length) {
        await Promise.all(
          uploadedProofs.map(({ uploadUrl, fileName, contentType, documentId }) => (api.uploadDocument({ uploadUrl, fileName, contentType, documentId, data: files[fileName].file }))))
        await api.completeProofUploadInventory({
          taskId: manualTask.taskId,
          foundResults: false,
          completedProof: uploadedProofs.map(({ documentId, contentType, fileName }) => ({ documentId, contentType, fileName })),
          results: [],
        })
      }
      setSubmitting(false)
      onSubmitSuccess()
    } catch (err) {
      setSubmitting(false)
      console.error('got error', err)
      const inner = findInnerError(err)
      setErrorDialog({
        show: true,
        errorMessage: `There was an error while submitting the form. Please try again or contact #oncall - ${inner}`,
      })
    }
  }, [api, manualTask, recordConfig, claimData, onSubmitSuccess, setErrorDialog, files])

  return (<>
    <Box component="form"
         onKeyDown={evt => {
           if (evt.key === 'Enter') {
             evt.preventDefault()
             return false
           }
           return true
         }}
         onSubmit={handleSubmit}
         onChange={() => setFormChanged(true)}>
      <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }} justifyContent="center">
            <Grid item xs={6}>
              {jurisdictionRecord && (<RecordFieldValue
               key={jurisdictionRecord.fieldName}
               name={jurisdictionRecord.fieldName}
               label={jurisdictionRecord.label}
               value={jurisdictionRecord.value}
               fieldType={jurisdictionRecord.dataType}
               options={jurisdictionRecord.options}
               editMode={false}
              />)}
              {recordConfig?.inputClaims?.length > 0 && recordConfig.inputClaims.map((field) => (<RecordFieldValue
               key={field.fieldName}
               name={field.fieldName}
               label={field.label}
               value={claimData[field.fieldName] || field.value}
               fieldType={field.dataType}
               options={field.options}
               editMode={true}
              />))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Grid container columnSpacing={{ xs: 10 }} rowSpacing={{ xs: 5 }}>
            <Grid item xs={3}>
              <Typography align='left'>Attach Proof</Typography>
            </Grid>
            <Grid item xs={9}>
              <ProofUploader files={files} setFiles={setFiles} />
            </Grid>
            <Grid item xs={4}/>
          </Grid>
          <Grid container columnSpacing={{ xs: 2 }} rowSpacing={{ xs: 1 }}>
            <Grid item xs={4}/>
            <Grid item xs={4}>
              <Button variant="contained" fullWidth type="submit" sx={{ mb: 2 }} disabled={!formChanged}>
                {submitting ? <CircularProgress color="secondary" size={20}/> : 'Submit claims amended'}
              </Button>
              <Button variant="contained" color="warning" fullWidth type="button" onClick={onCancel}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={4}/>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  </>)
}

export default AmendClaimsForm
