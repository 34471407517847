import React from 'react'
import { CircularProgress } from '@mui/material'

import ManualProfile from './Tasks/ManualProfile'
import MatchInventory from './Tasks/MatchInventory'
import CreateRecord from './Tasks/CreateRecord'
import MatchRecord from './Tasks/MatchRecord'

export const TASK_TYPE_MAP = {
  manual: {
    title: 'Match Profile',
    color: 'lightblue',
    widget: ManualProfile,
  },
  matchInventory: {
    title: 'Match Inventory',
    color: 'blue',
    widget: MatchInventory,
  },
  createRecord: {
    title: 'Create Record',
    color: 'green',
    widget: CreateRecord,
  },
  matchRecord: {
    title: 'Match Record',
    color: 'lightgreen',
    widget: MatchRecord,
  },
}

export const TaskTypeWrapper = ({ manualTask, setErrorDialog }) => {
  if (!manualTask || !manualTask.taskType) {
    return <CircularProgress />
  }
  if (TASK_TYPE_MAP[manualTask.taskType]) {
    return TASK_TYPE_MAP[manualTask.taskType].widget({
      manualTask,
      setErrorDialog,
    })
  }
  // Fallback in case we get an unsupported task type
  return <div>
    Unsupported taskType {manualTask.taskType} found!
  </div>
}

export const TaskTypeHeader = ({ manualTask }) => {
  if (!manualTask || !manualTask.taskType) {
    return <h1 />
  }
  if (TASK_TYPE_MAP[manualTask.taskType]) {
    return <h1>{TASK_TYPE_MAP[manualTask.taskType].title}</h1>
  }
  // Fallback in case we get an unsupported task type
  return <h1>{manualTask.taskType}</h1>
}

export default TaskTypeWrapper
