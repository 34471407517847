import { DateTime } from 'luxon'
import { formatDate } from './formatDate'
import { STATE_MAPPING } from './constants'

export const DEFAULT_EMPTY_VALUE = '-'

export const defaultVal = (value, emptyValue = DEFAULT_EMPTY_VALUE) =>
  value === undefined || value === null || value === '' ? emptyValue : value.toString()

export const stateMapper = (value) =>
  STATE_MAPPING[value] || defaultVal(value)
export const dateMapper = (date) => formatDate({ date, format: DateTime.DATE_SHORT })

export const dropdownMapper = (labelMap) => {
  const map = Object.fromEntries(labelMap.map(({ value, label }) => ([value, label])))
  return (value) => map[value] || defaultVal(value)
}

export const getRenderer = (fieldType) => {
  switch (fieldType) {
    case 'enum':
      return dropdownMapper
    case 'date':
      return dateMapper
    default:
      return defaultVal
  }
}
