import React, { useState, useContext, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Box, Button, Tabs, Tab } from '@mui/material'
import { ArrowBackIosNewRounded } from '@mui/icons-material'

import ApiContext from '../../Services/apiService'

import TaskTypeWrapper, { TaskTypeHeader } from './TaskTypeWrapper'
import { ErrorDialog } from './Shared/ErrorDialog'
import { MatchTaskHeader } from './Shared/MatchTaskHeader'
import { waitForStatus } from '../../utils/tasks'
import NotesTab from './Tasks/Notes/Notes'
import { useLoader } from '../../Services/useLoader'

const TaskHandler = () => {
  const { taskId } = useParams()

  const { showLoader, hideLoader } = useLoader()
  const [manualTask, setManualTask] = useState({})
  const [currentTab, setCurrentTab] = useState(0)
  const [errorDialog, setErrorDialog] = React.useState({
    show: false,
    errorMessage: '',
  })
  const handleCloseError = () => {
    setErrorDialog({
      show: false,
      errorMessage: '',
    })
  }
  const api = useContext(ApiContext)

  useEffect(() => {
    const fetchTask = async () => {
      const result = await api.getTicket(taskId)
      setManualTask(result)
    }

    fetchTask()
  }, [taskId, api])

  const handleTaskAction = useCallback(async (apiCall, successStatus, errorMessage, onSuccess = null) => {
    showLoader()
    try {
      const response = await apiCall()
      if (response !== 'Done') {
        setErrorDialog({ show: true, errorMessage })
        hideLoader()
        return
      }

      await waitForStatus(api, manualTask.taskId, successStatus)

      if (onSuccess) {
        onSuccess()
      }
    } catch (error) {
      setErrorDialog({ show: true, errorMessage })
    } finally {
      const newTask = await api.getTicket(manualTask.taskId)
      setManualTask(newTask)
      hideLoader()
    }
  }, [api, hideLoader, manualTask, showLoader])

  const assignTask = useCallback(async () => {
    const type = manualTask && manualTask.assignedUserId ? 'unassign' : 'assign'
    await handleTaskAction(
      () => api.assignTask({ type, taskId: manualTask.taskId }),
      type === 'assign' ? 'ASSIGNED' : 'PENDING',
      'Error assigning task',
    )
  }, [api, handleTaskAction, manualTask])

  const onHoldTask = useCallback(async (reason) => {
    await handleTaskAction(
      () => api.putOnHoldTask(manualTask.taskId, reason),
      'ON_HOLD',
      'Error on hold task',
    )
  }, [api, handleTaskAction, manualTask])

  const resumeTask = useCallback(async () => {
    await handleTaskAction(
      () => api.resumeTask(manualTask.taskId),
      ['ASSIGNED', 'PENDING'],
      'Error resuming task',
    )
  }, [api, handleTaskAction, manualTask])

  const setTab = (_, newValue) => {
    setCurrentTab(newValue)
  }

  return (
    <div className="taskQueue">
      <ErrorDialog
        errorMessage={errorDialog.errorMessage}
        open={errorDialog.show}
        onClose={handleCloseError}
      />
      <Box>
      {/* <Container maxWidth="lg" sx={{ bgcolor: '#f8f8f8', overflow: 'scroll' }}> */}

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <BackButton />
          <TaskTypeHeader manualTask={manualTask} />
          {/* Empty Placeholder for proper spacing */}
          <div />
        </Box>
        <MatchTaskHeader
         manualTask={manualTask}
         assignTask={assignTask}
         onHoldTask={onHoldTask}
         resumeTask = {resumeTask}
        />

        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <Tabs value={currentTab} onChange={setTab} aria-label="basic tabs example">
            <Tab label="General information" />
            <Tab label="Activity" />
          </Tabs>
        </Box>
        <Box display={currentTab === 0 ? 'block' : 'none'}>
          <TaskTypeWrapper manualTask={manualTask} setErrorDialog={setErrorDialog} />
        </Box>
        <Box display={currentTab === 1 ? 'block' : 'none'}>
          <NotesTab taskId={taskId} />
        </Box>
      {/* </Container> */}
      </Box>
    </div>
  )
}

const BackButton = () => {
  const navigator = useNavigate()

  const onBackPressed = () => {
    navigator('/home')
  }
  return (
    <Button variant="text" startIcon={<ArrowBackIosNewRounded />} onClick={onBackPressed}>Back</Button>
  )
}

export default TaskHandler
