import React, { useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ApiContext from '../../Services/apiService'
import MarkdownRenderer from '../../Components/MarkdownRenderer'

const baseRoute = '/docs'

const Documentation = () => {
  const { '*': path } = useParams()
  const [text, setText] = useState('')
  const api = useContext(ApiContext)

  useEffect(() => {
    const fetchDocumentation = async () => {
      const finalPath = path || 'README.md'
      const result = await api.getDocumentation(finalPath)
      setText(result)
    }
    fetchDocumentation()
  }, [path, api])

  return (
    <>
      <MarkdownRenderer markdownText={text} baseRoute={baseRoute} />
    </>
  )
}

export default Documentation
