export enum NOTE_TYPE {
  PENDING = 'pending',
  ASSIGNED = 'assigned',
  ON_HOLD = 'on_hold',
  COMPLETED_POSITIVE = 'completed_positive',
  COMPLETED_NEGATIVE = 'completed_negative',
  CANCELLED = 'cancelled',
  CLAIMS_AMENDED = 'claims_amended',
  USER_COMMENT = 'user_comment',
}

export type Note = {
  noteId: string
  taskId: string
  userId?: string
  user: {
    userId: string
    email: string
  }
  createdAt: number
  orderId?: string
  noteType: NOTE_TYPE
  noteTitle: string
  noteText?: string
}
